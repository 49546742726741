import { RentFrequencyEnum } from '@/models/landlord/rental-property.model';

export interface Application {
  id: number;
  status: ApplicationStatus;
  rentOffered: number;
  rentFrequency: RentFrequencyEnum;
  leaseType: RentalLeaseType;
  startDate: string;
  endDate: string;
  termDurationNumber: number;
  termDurationType: TenancyDurationType;
  termDurationDays: number;
  dateApplied: string;
  marketingRent: number;
  marketingRentFrequency: number;
  percentageDifference: number;
  renterNames: string;
  responsibleAgentPhone: string;
  responsibleAgentEmail: string;
  responsibleAgentAvatar: string;
  responsibleAgentName: string;
}

export enum ApplicationStatus {
  NewApplication = 0,
  Accepted = 1,
  HoldingDepositPaid = 2,
  Withdrawn = 3,
  Rejected = 4,
}

export enum RentalLeaseType {
  None = 0,
  AssuredShortholdTenancy = 1,
  AssuredTenancy = 2,
  LongLeasehold = 3,
  Licence = 4,
  Periodic = 5,
  CompanyTenancy = 6,
  PeriodicOccupationalContract = 7,
  FixedTermOccupationalContract = 8,
}

export const RentalLeaseTypeLabels = {
  [RentalLeaseType.None]: 'None',
  [RentalLeaseType.AssuredShortholdTenancy]: 'Assured Shorthold Tenancy',
  [RentalLeaseType.AssuredTenancy]: 'Assured Tenancy',
  [RentalLeaseType.LongLeasehold]: 'Long Leasehold',
  [RentalLeaseType.Licence]: 'Licence',
  [RentalLeaseType.Periodic]: 'Periodic',
  [RentalLeaseType.CompanyTenancy]: 'Company Tenancy',
  [RentalLeaseType.PeriodicOccupationalContract]: 'Periodic Occupational Contract',
  [RentalLeaseType.FixedTermOccupationalContract]: 'Fixed Term Occupational Contract',
};

export enum TenancyDurationType {
  Weeks = 1,
  Months = 2,
}

export const TenancyDurationTypeLabels = {
  [TenancyDurationType.Weeks]: 'week',
  [TenancyDurationType.Months]: 'month',
};
